import * as React from "react"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { AgencySite } from "../../pages/agencies"

export default function AgencySiteCard({ name, url, image }: AgencySite) {
  return (
    <div
      css={theme => ({
        borderRadius: theme.radii[3],
        boxShadow: theme.shadows.raised,
        overflow: `hidden`,
      })}
    >
      <GatsbyImage alt={name} image={image?.gatsbyImageData} />
      <div
        css={theme => ({
          padding: theme.space[4],
        })}
      >
        <div
          css={theme => ({
            fontSize: theme.fontSizes[2],
            fontWeight: theme.fontWeights.bold,
          })}
        >
          {name}
        </div>
        <div>
          <a
            href={url}
            target="_blank"
            css={theme => ({
              fontSize: theme.fontSizes[0],
              color: theme.colors.grey[80],
              textDecoration: "none",
              "&:hover, &:focus": {
                color: theme.colors.gatsby,
              },
            })}
          >
            {url}
          </a>
        </div>
      </div>
    </div>
  )
}
