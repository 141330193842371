import * as React from "react"
import { Agency } from "../../pages/agencies"

function Row(props: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      {...props}
      css={theme => ({
        display: "grid",
        gridTemplateColumns: `7rem minmax(0, 1fr)`,
        alignItems: "baseline",
        paddingBottom: theme.space[4],

        "&:not(:first-child)": {
          paddingTop: theme.space[4],
        },

        "&:not(:last-child)": {
          borderBottom: `1px solid ${theme.colors.grey[20]}`,
        },
      })}
    />
  )
}

function Label(props: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      {...props}
      css={theme => ({
        fontSize: theme.fontSizes[0],
        fontWeight: theme.fontWeights.bold,
        textTransform: "uppercase",
        letterSpacing: theme.letterSpacings.tracked,
      })}
    />
  )
}

function Details(props: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      {...props}
      css={theme => ({
        "& a": {
          color: theme.colors.gatsby,
        },
      })}
    />
  )
}

export default function MetaList({
  website,
  email,
  phone,
  regions,
  languages,
}: Agency) {
  return (
    <div>
      {website && (
        <Row>
          <Label>Website</Label>
          <Details>
            <a href={website} target="_blank">
              {website}
            </a>
          </Details>
        </Row>
      )}
      {email && (
        <Row>
          <Label>Email</Label>
          <Details>
            <a href={`mailto:${email}`}>{email}</a>
          </Details>
        </Row>
      )}
      {phone && (
        <Row>
          <Label>Phone</Label>
          <Details>{phone}</Details>
        </Row>
      )}
      {regions && (
        <Row>
          <Label>Regions</Label>
          <Details>{regions.join(", ")}</Details>
        </Row>
      )}
      {languages && (
        <Row>
          <Label>Languages</Label>
          <Details>{languages?.join(", ")}</Details>
        </Row>
      )}
    </div>
  )
}
