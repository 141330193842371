import * as React from "react"
import * as Yup from "yup"
import { Formik, Form } from "formik"
import {
  Button,
  InputConnectedField,
  TextAreaConnectedField,
  SelectConnectedField,
  Heading,
  ThemeCss,
} from "gatsby-interface"
import { Agency } from "../../pages/agencies/index"
// import { useTracker, BigQueryEventType } '../../dashboard/src/modules/analytics'
// Only two event types are currently used (click and visit)

export type AgencyContactForm = {
  agency: Agency
}

export type FormValues = {
  firstName: string
  lastName: string
  companyName: string
  email: string
  description: string
}

export const validationSchema = Yup.object().shape<FormValues>({
  firstName: Yup.string().required(`First name is required`),
  lastName: Yup.string().required(`Last name is required`),
  companyName: Yup.string().required(`Company name is required`),
  email: Yup.string()
    .email()
    .required(`Email address is reqired`),
  description: Yup.string().required(`Description is reqired`),
})

export function AgencyContactForm({ agency }: AgencyContactForm) {
  // const { trackBigQuery } = useTracker()

  const handleSubmit = async (values, { setSubmitting, setStatus }) => {
    setStatus({})
    const mailto = agency.email
    const body = JSON.stringify({
      ...values,
      mailto,
    })

    const data = await fetch("/api/agencies/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body,
    }).then(res => res.json())

    setSubmitting(false)
    if (data.error) {
      setStatus({ error: data.message })
      console.error(data.message, data.error)
    }
    if (data.success) {
      setStatus({ success: true })
    }
  }

  // Disable contact form if no email is set
  if (!agency.email) return false

  return (
    <div id="contact" css={rootCss}>
      <Heading>{`Contact ${agency.name}`}</Heading>
      <Formik
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={true}
        initialValues={{
          firstName: ``,
          lastName: ``,
          companyName: ``,
          email: ``,
          description: ``,
        }}
        initialStatus={{}}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, status }) => {
          return (
            <Form noValidate css={formCss}>
              {status.success && <div>Email successfully sent!</div>}
              {status.error && (
                <div>There was an error contacting this Agency</div>
              )}
              <div css={columnsCss}>
                <InputConnectedField
                  name="firstName"
                  label="First name"
                  placeholder="Summer"
                />
                <InputConnectedField
                  name="lastName"
                  label="Last name"
                  placeholder="Smith"
                />
                <InputConnectedField
                  name="companyName"
                  label="Company name"
                  placeholder="Example, Inc."
                />
                <InputConnectedField
                  name="email"
                  label="Email"
                  placeholder="summer.smith@example.com"
                />
              </div>
              <TextAreaConnectedField
                name="description"
                label="Brief description of your project scope"
                hint="Maximum of 2000 characters."
                required
              />
              <Button
                type="submit"
                loading={isSubmitting}
                loadingLabel="Submitting"
                disabled={isSubmitting}
                size="L"
                css={submitCss}
              >
                Send
              </Button>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

/* styles */

const rootCss: ThemeCss = theme => ({
  marginTop: theme.space[10],
})

const formCss: ThemeCss = theme => ({
  display: `grid`,
  gap: theme.space[5],
  marginTop: theme.space[6],
})

const columnsCss: ThemeCss = theme => ({
  display: `grid`,
  gap: theme.space[6],

  [theme.mediaQueries.desktop]: {
    gridTemplateColumns: `1fr 1fr`,
  },
})

const submitCss: ThemeCss = theme => ({
  justifySelf: `start`,
  marginTop: theme.space[5],
})
