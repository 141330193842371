import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { ContentfulAsset } from "../../pages/agencies"
import { ThemeCss } from "gatsby-interface"

export interface AgencyAwardsProps {
  awards: ContentfulAsset[]
}

export default function AgencyAwards({ awards }: AgencyAwardsProps) {
  return (
    <>
      {awards?.map(award => (
        <GatsbyImage
          key={award.id}
          alt={award.title}
          image={award.gatsbyImageData}
          css={itemCss}
        />
      ))}
    </>
  )
}

/* styles */

const itemCss: ThemeCss = theme => ({
  margin: theme.space[4],
})
