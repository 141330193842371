import * as React from "react"
import { LinkButton, Text } from "gatsby-interface"

export default function AgencyFooter() {
  return (
    <div
      css={theme => ({
        display: "grid",
        alignItems: "center",
        gap: theme.space[6],
        padding: theme.space[8],
        borderRadius: theme.radii[3],
        border: "1px solid",
        borderColor: theme.colors.grey[20],

        [theme.mediaQueries.phablet]: {
          gridTemplateColumns: `1fr auto`,
        },
      })}
    >
      <Text size="2XL" noMarginBottom={true}>
        <strong>Would you like to grow your business with Gatsby?</strong>
      </Text>

      <LinkButton to="/agencies/submissions">Become a Partner</LinkButton>
    </div>
  )
}
