import * as React from "react"

export interface ExpertisesBadgesProps {
  expertises: string[]
}

export default function ExpertisesBadges({
  expertises,
}: ExpertisesBadgesProps) {
  return (
    <ul
      css={theme => ({
        listStyle: "none",
        margin: 0,
        padding: 0,
        marginBottom: `-${theme.space[4]}`, // nulifies the item bottom margin
      })}
    >
      {expertises.map(expertise => (
        <li
          key={expertise}
          css={theme => ({
            display: "inline-block",
            marginRight: theme.space[4],
            color: theme.colors.grey[80],
            backgroundColor: theme.colors.grey[10],
            borderRadius: theme.radii[2],
            paddingTop: theme.space[1],
            paddingBottom: theme.space[1],
            paddingLeft: theme.space[3],
            paddingRight: theme.space[3],
            fontSize: theme.fontSizes[0],
            marginBottom: theme.space[4],
          })}
        >
          {expertise}
        </li>
      ))}
    </ul>
  )
}
