import * as React from "react"
import { graphql, Link } from "gatsby"
import { LinkButton, Theme } from "gatsby-interface"
import { MdCheck } from "react-icons/md"
import Layout from "../../layout"
import SiteHead from "../../head"
import config from "../../config"
import Container from "../../layout/container"
import SiteCard from "../../components/agencies/site-card"
import Footer from "../../components/agencies/footer"
import Awards from "../../components/agencies/awards"
import CMSBadges from "../../components/agencies/cms-badges"
import ExpertisesBadges from "../../components/agencies/expertises-badges"
import MetaList from "../../components/agencies/meta-list"
import Image from "../../components/agencies/image"
import { Agency, ContentfulLongText } from "."
import { AgencyContactForm } from "../../components/agencies/AgencyContactForm"
import {
  SocialShare,
  SocialShareLeftSideStickyContainer,
} from "../../components/social-share"

function LongText({ childMarkdownRemark }: ContentfulLongText) {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: childMarkdownRemark.html,
      }}
    />
  )
}

function SectionHeading(props: React.HTMLAttributes<HTMLHeadingElement>) {
  return (
    <h2
      css={theme => ({
        fontSize: theme.fontSizes[0],
        fontWeight: theme.fontWeights.bold,
        textTransform: "uppercase",
        letterSpacing: theme.letterSpacings.tracked,
        marginBottom: theme.space[4],
      })}
      {...props}
    />
  )
}

interface AgencyDetailProps {
  location: Location
  data: {
    agency: Agency
  }
}

export function Head(props) {
  const title = props.data.agency?.name
  // This could be consolidated into a single type/shape on the Contentful side
  // where the ContentfulHead component could handle this internally
  const socialMediaPath = props.data.agency?.socialMediaImage?.resize?.src
  const image = config.url + socialMediaPath

  return <SiteHead {...props} title={title} image={image} />
}

export default function AgencyDetail({ data, location }: AgencyDetailProps) {
  const { agency } = data

  const socialMediaImageUrl = agency?.socialMediaImage?.resize?.src
    ? `${config.url}${agency.socialMediaImage.resize.src}`
    : undefined

  return (
    <Layout>
      <Container
        css={(theme: Theme) => ({
          paddingTop: theme.space[6],
          paddingBottom: theme.space[6],

          [theme.mediaQueries.desktop]: {
            paddingLeft: theme.space[14],
          },
        })}
      >
        <Link
          to=".."
          css={theme => ({
            fontSize: theme.fontSizes[1],
            color: theme.colors.grey[80],
            textDecoration: "none",
            "&:hover, &:focus": {
              color: theme.colors.gatsby,
            },
          })}
        >
          ← Back to Agency index
        </Link>

        <article
          css={theme => ({
            marginTop: theme.space[6],
            marginBottom: theme.space[8],
            position: `relative`,
          })}
        >
          <header
            css={theme => ({
              display: "flex",
              alignItems: "center",
              gap: theme.space[5],
              flexWrap: "wrap",
              paddingTop: theme.space[6],
              paddingBottom: theme.space[10],
            })}
          >
            {agency.logo && (
              <Image
                {...agency.logo}
                css={theme => [
                  {
                    height: `32px`,
                    width: `auto`,
                    marginRight: theme.space[6],
                  },

                  agency.logo.width &&
                    agency.logo.width > 240 && {
                      maxWidth: `240px`,
                      height: `auto`,
                    },
                ]}
              />
            )}

            {agency.awards && (
              <div
                css={_theme => ({
                  display: `flex`,
                  alignItems: "center",
                  flexWrap: "wrap",
                })}
              >
                <Awards awards={agency.awards} />
              </div>
            )}

            <LinkButton
              variant="SECONDARY"
              href="#contact"
              css={{ marginLeft: `auto` }}
            >
              Get in touch
            </LinkButton>
          </header>

          {agency.sites && (
            <section
              css={theme => ({
                display: "grid",
                gap: theme.space[6],
                justifyContent: `center`,
                gridTemplateColumns: "repeat(auto-fit, minmax(300px, 425px))",
                marginBottom: theme.space[9],

                [theme.mediaQueries.desktop]: {
                  marginBottom: 0,
                },
              })}
            >
              {agency.sites?.map(site => (
                <SiteCard key={site.id} {...site} />
              ))}
            </section>
          )}

          {agency.name && (
            <SocialShareLeftSideStickyContainer topOffset="10rem">
              <SocialShare
                url={`https://www.gatsbyjs.com${location.pathname}`}
                title={`Check out this ${agency.name} profile at Gatsby Agency Directory`}
                mailToSubject={`Check out the ${agency.name} profile at Gatsby Agency Directory`}
                mailToBody={`Check out the "${agency.name}" profile at Gatsby Agency Directory - https://www.gatsbyjs.com${location.pathname}`}
              />
            </SocialShareLeftSideStickyContainer>
          )}

          <div
            css={theme => ({
              display: `grid`,
              marginTop: theme.space[8],
              gap: theme.space[8],

              [theme.mediaQueries.desktop]: {
                gridTemplateColumns: `1fr 450px`,
              },
            })}
          >
            <section css={{ maxWidth: `40rem` }}>
              {agency.name && <h1>About {agency.name}</h1>}

              {agency.introduction && <LongText {...agency.introduction} />}

              {agency.specialization && (
                <React.Fragment>
                  <h2>We specialize in</h2>
                  <LongText {...agency.specialization} />
                </React.Fragment>
              )}

              {agency.expertises && (
                <React.Fragment>
                  <h2>Technical expertise</h2>
                  <ul
                    css={_theme => ({
                      listStyle: "none",
                      margin: 0,
                      padding: 0,
                    })}
                  >
                    {agency.expertises?.map(expertise => (
                      <li
                        key={expertise}
                        css={theme => ({
                          display: "flex",
                          alignItems: "center",
                          gap: theme.space[4],
                        })}
                      >
                        <MdCheck />
                        {expertise}
                      </li>
                    ))}
                  </ul>
                </React.Fragment>
              )}

              <AgencyContactForm agency={agency} />
            </section>

            <aside
              css={theme => ({
                display: `grid`,
                gap: theme.space[10],
                alignContent: `start`,
                marginTop: theme.space[6],

                [theme.mediaQueries.desktop]: {
                  marginTop: theme.space[12],
                },
              })}
            >
              {agency.platforms && (
                <section>
                  <SectionHeading>CMS Platforms</SectionHeading>
                  <CMSBadges platforms={agency.platforms} />
                </section>
              )}

              {agency.expertises && (
                <section>
                  <SectionHeading>Technical Expertise</SectionHeading>
                  <ExpertisesBadges expertises={agency.expertises} />
                </section>
              )}

              <MetaList {...agency} />
            </aside>
          </div>
        </article>
        <Footer />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query AgencyDetail($id: String!) {
    agency: contentfulAgency(id: { eq: $id }) {
      id
      name
      logo {
        id
        gatsbyImageData(layout: CONSTRAINED, height: 32)
        url
        height
        width
      }
      introduction {
        childMarkdownRemark {
          html
        }
      }
      specialization {
        childMarkdownRemark {
          html
        }
      }
      regions
      languages
      platforms
      expertises
      website
      email
      phone
      tags
      awards {
        id
        title
        gatsbyImageData(height: 32)
      }
      sites {
        id
        name
        url
        image {
          id
          gatsbyImageData(width: 480)
        }
      }
      socialMediaImage {
        resize(width: 1200, height: 630, format: JPG, fit: COVER) {
          src
        }
      }
    }
  }
`
