import * as React from "react"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"

interface ImageProps {
  id: string
  title: string
  gatsbyImageData?: IGatsbyImageData
  url?: string
  width?: number
  height?: number
}

export default function Image({
  id,
  title,
  gatsbyImageData,
  url,
  width,
  height,
  ...props
}: ImageProps) {
  if (!gatsbyImageData) {
    return (
      <img
        width={width}
        height={height}
        alt={title}
        src={url}
        css={{ margin: 0 }}
        {...props}
      />
    )
  }

  return (
    <GatsbyImage
      css={{ margin: 0 }}
      alt={title}
      image={gatsbyImageData}
      {...props}
    />
  )
}
